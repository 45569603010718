
import EvercamLoadingAnimation from "@evercam/shared/components/EvercamLoadingAnimation"
import Painterro from "painterro"

export default {
  name: "ImageEditor",
  components: {
    EvercamLoadingAnimation,
  },
  props: {
    src: {
      type: String,
      default: "",
    },
    options: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      instance: null,
      defaultOptions: {
        id: "painterroImageEditor",
      },
      isLoading: false,
    }
  },
  watch: {
    src(value) {
      this.instance.clear()
      this.instance.show(value)
    },
  },
  mounted() {
    this.$addEventListener("orientationchange", this.handleOrientationChange)
    localStorage?.removeItem("painterro-data")
    const img = new Image()
    this.isLoading = true
    img.onload = () => {
      const imgSize = Math.max(img.naturalWidth, img.naturalHeight)
      const lineWidth = imgSize / 200
      this.defaultOptions.defaultLineWidth = lineWidth
      this.defaultOptions.defaultArrowLength = lineWidth * 2.5
      this.instance = Painterro({
        ...this.defaultOptions,
        ...this.options,
      }).show(this.src)
      this.instance.selectTool = this.selectTool
      this.selectTool("brush")
      this.$emit("init", this.instance)
      this.isLoading = false
    }
    img.onerror = () => {
      this.isLoading = false
      this.$notifications.warn(this.$t("content.snapshot_load_failed"))
    }
    img.src = this.src
  },
  methods: {
    selectTool(name) {
      const tool =
        this.instance.tools.find((t) => t.name === name) ||
        this.instance.defaultTool
      if (tool) {
        this.instance?.closeActiveTool(true)
        this.instance?.setActiveTool(tool)
      }
    },
    handleOrientationChange() {
      this.$setTimeout(() => {
        const event = new Event("resize")
        window.dispatchEvent(event) // hack to update the image dimensions on change orientation
      }, 500)
    },
  },
}
