export default {
  liveView: "liveView",
  editSnapshot: "editSnapshot",
  weather: "weather",
  cameraInfo: "cameraInfo",
  analyticsEvent: "analyticsEvent",
  ready: "ready",
  info: "info",
  gateReport: "gateReport",
  createCompare: "createCompare",
  uploadDrone: "uploadDrone",
  uploadBIM: "uploadBIM",
  history: "history",
  shareLink: "shareLink",
  loadForgeModel: "loadForgeModel",
  loadSitePlannerList: "loadSitePlannerList",
}
