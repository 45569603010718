import { AnalyticsEvent } from "@evercam/shared/types"

export default {
  undo: {
    id: "undo",
    eventId: AnalyticsEvent.EditToolUndo,
  },
  redo: {
    id: "redo",
    eventId: AnalyticsEvent.EditToolRedo,
  },
  color: {
    id: "color",
    eventId: AnalyticsEvent.EditToolSelectColor,
  },
  brush: {
    id: "brush",
    eventId: AnalyticsEvent.EditToolSelectTool,
    params: { tool: "brush" },
  },
  pixelize: {
    id: "pixelize",
    eventId: AnalyticsEvent.EditToolSelectTool,
    params: { tool: "blur" },
  },
  arrow: {
    id: "arrow",
    eventId: AnalyticsEvent.EditToolSelectTool,
    params: { tool: "arrow" },
  },
  text: {
    id: "text",
    eventId: AnalyticsEvent.EditToolSelectTool,
    params: { tool: "text" },
  },
  selection: {
    id: "selection",
    eventId: AnalyticsEvent.EditToolSelectTool,
    params: { tool: "selection" },
  },
  ellipse: {
    id: "ellipse",
    eventId: AnalyticsEvent.EditToolSelectTool,
    params: { tool: "ellipse" },
  },
  rect: {
    id: "rect",
    eventId: AnalyticsEvent.EditToolSelectTool,
    params: { tool: "rect" },
  },
  line: {
    id: "line",
    eventId: AnalyticsEvent.EditToolSelectTool,
    params: { tool: "line" },
  },
  crop: {
    id: "crop",
    eventId: AnalyticsEvent.EditToolSelectTool,
    params: { tool: "crop" },
  },
}
