
export default {
  props: {
    origin: {
      type: String,
      default: "",
    },
    params: {
      type: String,
      default: "",
    },
    height: {
      type: [Number, undefined],
      default: undefined,
    },
    width: {
      type: [Number, undefined],
      default: undefined,
    },
  },
  data: () => ({
    iframeStyle: {
      border: "none",
    },
  }),

  mounted() {
    this.$addEventListener("message", this.onMessageReceived)
  },
  methods: {
    async onMessageReceived(evt) {
      if (evt?.origin !== this.origin) {
        return
      }

      this.$emit("on-message", { ...evt.data, origin: evt.origin })
    },

    updateFrameDimensions() {
      if (this.height && this.width) {
        this.iframeStyle = {
          height: `${this.height}px`,
          width: `${this.width}px`,
          border: "none",
        }

        return
      }

      const iframe = this.$refs.container
      const container = iframe?.getBoundingClientRect()
      this.$clearTimeouts()
      this.$setTimeout(() => {
        this.iframeStyle = {
          height: `${window.innerHeight - container?.top}px`,
          width: `${window.innerWidth - container?.left}px`,
          border: "none",
        }
      }, 100)
    },
  },
}
