
import editTools from "@evercam/shared/constants/editTools"

export default {
  name: "EditorToolbar",
  props: {
    editor: {
      type: [HTMLElement, Object],
      default: () => ({}),
    },
    undoDisabled: {
      type: Boolean,
      default: true,
    },
    redoDisabled: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      editorInstance: null,
      colors: [
        "#ff0300",
        "#F5B041",
        "#FAE356",
        "#7AE571",
        "#8635b1",
        "#000000",
        "#E5E5E5",
        "#328FF6",
        "#74DDFB",
      ],
      selectedColor: "#ff0300",
      activeTool: "brush",
      editTools,
    }
  },
  watch: {
    editor: {
      immediate: true,
      handler(val) {
        this.selectTool(this.editTools.brush.id)
        this.editorInstance = val?.instance
        if (this.editorInstance) {
          this.bindEditor()
        }
      },
    },
  },
  methods: {
    getToolHighlightColor(tool) {
      return this.activeTool === tool ? "primary" : "on_background"
    },
    bindEditor() {
      this.selectTool(this.activeTool)
      this.$addEventListener("mouseup", this.callBlurEnd, this.editor?.$el)
      this.$addEventListener("touchend", this.callBlurEnd, this.editor?.$el)
    },
    callBlurEnd() {
      if (this.activeTool === this.editTools.pixelize.id) {
        this.$setTimeout(this.onBlurEnd)
      }
    },
    selectTool(name, isSelectionTool = false) {
      const tool = this.editTools[name]
      if (tool && tool.id !== this.activeTool) {
        this.$analytics.saveEvent(tool.eventId, tool.params || {})
      }
      this.activeTool = name
      this.editorInstance?.selectTool(
        isSelectionTool ? this.editTools.selection.id : name
      )
      if (!isSelectionTool) {
        this.selectColor(this.selectedColor)
      }
    },
    selectColor(color) {
      if (color !== this.selectedColor) {
        this.$analytics.saveEvent(this.editTools.color.eventId, { color })
      }
      this.selectedColor = color
      const toolHasColorControls =
        this.editorInstance?.activeTool?.controls?.find(
          (c) => c.type === "color"
        )
      if (!toolHasColorControls) {
        return
      }
      this.editorInstance?.colorPicker.open(
        this.editorInstance?.colorWidgetState.line
      )
      this.editorInstance?.colorPicker.setActiveColor(color)
      this.editorInstance?.colorPicker.close()
      this.editorInstance?.selectTool(this.activeTool)
    },
    onCropStart() {
      this.selectTool(this.editTools.crop.id, true)
      this.$emit("crop-start")
    },
    onBlurStart() {
      this.selectTool(this.editTools.pixelize.id, true)
    },
    onBlurEnd() {
      this.editorInstance?.selectTool(this.editTools.pixelize.id)
      this.$setTimeout(this.onBlurStart)
    },
  },
}
