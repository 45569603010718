
import { mapStores } from "pinia"
import { useConnectorStore } from "@/stores/connector"
import AutodeskFileSelection from "@/components/connectors/AutodeskFileSelection"

export default {
  components: {
    AutodeskFileSelection,
  },
  props: {
    isSelectingOnlyFolder: {
      type: Boolean,
      default: true,
    },
    selectedHub: {
      type: Object,
      default: null,
    },
    selectedProject: {
      type: Object,
      default: null,
    },
    selectedItem: {
      type: Object,
      default: null,
    },
  },
  computed: {
    ...mapStores(useConnectorStore),
    selectedAutodeskHub: {
      get() {
        return this.selectedHub
      },
      set(value) {
        console.log(value)
        this.$emit("update:selectedHub", value)
      },
    },
    selectedAutodeskProject: {
      get() {
        return this.selectedProject
      },
      set(value) {
        this.$emit("update:selectedProject", value)
      },
    },
    selectedAutodeskItem: {
      get() {
        return this.selectedItem
      },
      set(value) {
        console.log(value)
        this.$emit("update:selectedItem", value)
      },
    },
    autodeskFolderName() {
      return this.selectedAutodeskItem
        ? (this.selectedAutodeskItem.parentName ?? "") +
            this.selectedAutodeskItem.text
        : null
    },
  },
  methods: {
    handleHubChange(hub) {
      this.selectedAutodeskItem = null
      this.selectedAutodeskProject = null
      this.connectorStore.fetchAutodeskProjects(hub.value)
    },
    handleProjectChange(project) {
      this.selectedAutodeskItem = null
      this.connectorStore.fetchAutodeskFolders(
        this.selectedAutodeskHub?.value,
        project.value
      )
    },
  },
}
