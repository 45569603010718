
import { mapStores } from "pinia"

import {
  _sitePlannerProvidersParams,
  _sitePlannerProviders,
} from "@evercam/shared/constants/sitePlannerProviders"
import MESSAGES_TYPES from "@evercam/shared/constants/iframeMessagesTypes"
import { UnitSystem } from "@evercam/shared/types"
import { useProjectStore } from "@/stores/project"
import { useAccountStore } from "@/stores/account"
import { useConnectorStore } from "@/stores/connector"
import { useLayoutStore } from "@/stores/layout"

import { WatermarkedImageDownloadOrigin } from "@evercam/shared/types"
import EvercamLoadingAnimation from "@evercam/shared/components/EvercamLoadingAnimation"
import FrameViewer from "@/components/3dAppsDialogs/FrameViewer"
import SnapshotEditor from "@/components/SnapshotEditor"

export default {
  name: "SitePlannerViewer",
  components: { EvercamLoadingAnimation, FrameViewer, SnapshotEditor },
  props: {
    viewType: {
      type: String,
      default: "list",
    },
    unitSystem: {
      type: String,
      default: UnitSystem.Metric,
    },
    projectId: {
      type: String,
      default: null,
    },
    isNew: {
      type: Boolean,
      default: false,
    },
    onBackToListClicked: {
      type: Function,
      default: () => {},
    },
  },
  data: () => ({
    providerParams: _sitePlannerProvidersParams,
    isEditingImage: false,
    isLoadingIFrame: true,
    isSitePlannerViewerMounted: false,
    currentSnapshot: null,
    currentQueryParams: {},
    WatermarkedImageDownloadOrigin,
  }),
  computed: {
    ...mapStores(
      useProjectStore,
      useAccountStore,
      useConnectorStore,
      useLayoutStore
    ),
    origin() {
      return `${this.$config.public.sitePlannerIframeUrl}`
    },
    params() {
      if (!this.isSitePlannerViewerMounted) {
        return // Makes sure that the iframe is not loaded before the component is mounted
      }
      const frame = document.querySelector(".site-planner-viewer")
      const baseParams = `${this.providerParams[_sitePlannerProviders.Evercam]}`
      const projectIdParam = this.projectId ? `${this.projectId}` : ""
      const queryParams = new URLSearchParams({
        ...this.$route.query,
        unit: this.unitSystem,
        page: this.viewType,
        theme: this.$vuetify.theme.dark ? "dark" : "light",
        iframe_dimensions: `${frame?.offsetWidth},${frame?.offsetHeight}`,
        isNewSitePlan: this.isNew,
      }).toString()

      return `${baseParams}${projectIdParam}&${queryParams}`
    },
  },
  watch: {
    "$route.query": {
      handler(newQuery) {
        this.currentQueryParams = { ...this.currentQueryParams, ...newQuery }
      },
      immediate: true,
    },
  },
  mounted() {
    this.isSitePlannerViewerMounted = true
    if (this.viewType == "list") {
      this.pushQueryParams({ sitePlanId: this.projectId })
    }
  },
  beforeDestroy() {
    this.pushQueryParams({ sitePlanId: "" })
  },
  methods: {
    async onMessageReceived(data) {
      switch (data?.type) {
        case MESSAGES_TYPES.editSnapshot:
          if (!this.isEditingImage) {
            this.openImageEditor(data?.url)
          }
          break
        case MESSAGES_TYPES.loadSitePlannerList:
          this.onBackToListClicked()
          break
        case MESSAGES_TYPES.ready:
          this.isLoadingIFrame = false
          this.sendProjectInfo(data?.origin)
          break
        default:
          break
      }
    },
    openImageEditor(urlData) {
      this.currentSnapshot = {
        snapshot: {
          data: urlData,
          createdAt: new Date(),
        },
      }
      this.isEditingImage = true
    },
    pushQueryParams(parameters) {
      this.currentQueryParams = {
        ...this.currentQueryParams,
        ...parameters,
      }
      this.$router.push({
        path: this.$route.path,
        query: this.currentQueryParams,
      })
    },
    async sendProjectInfo(origin) {
      const frameViewerRef = this.$refs.frameViewer // This can only be a SitePlanner-iFrame
      const frameViewerContainer = frameViewerRef.$refs.container

      frameViewerContainer?.contentWindow?.postMessage(
        {
          type: MESSAGES_TYPES.info,
          user: {
            email: this.accountStore.email,
            name: this.accountStore.fullname,
          },
        },
        origin
      )
    },
  },
}
