
import { mapStores } from "pinia"
import { useConnectorStore } from "@/stores/connector"

export default {
  props: {
    value: {
      type: Object,
      default: null,
    },
    includeDerivatives: {
      type: Boolean,
      default: true,
    },
    selectedAutodeskProject: {
      type: Object,
      default: null,
    },
  },
  computed: {
    ...mapStores(useConnectorStore),
    selectedAutodeskFolder: {
      get() {
        return this.value
      },
      set(value) {
        if (this.includeDerivatives && value[0].isFolder) {
          return
        }
        this.$emit("input", value[0])
      },
    },
  },
  methods: {
    async fetchAutodeskFolderContent(folder) {
      await this.connectorStore.fetchAutodeskSubfolder(
        folder,
        this.selectedAutodeskProject?.value,
        this.includeDerivatives
      )
    },
    autodeskFolderIcon(item, open) {
      if (item?.isFolder) {
        return open ? "fas fa-folder-open" : "fas fa-folder"
      } else {
        return "fas fa-cubes"
      }
    },
  },
}
