
export default {
  props: {
    pdfContent: {
      type: Object,
      default: () => {},
    },
    width: {
      type: Number,
      default: 0,
    },
  },
  methods: {
    capitalize(string) {
      return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase()
    },
  },
}
